export const WORDS = [
'trains',
'alfie',
'pool',
'winnie',
'nomads',
'shield',
'bins',
'burger',
'hippo',
'socks',
'park',
'sleep',
'santa',
'early',
'doodad',
'kiwi',
'karen',
'salad',
'spicy',
'stripe',
'heeler',
'hide',
'pirate',
'wagon',
'books',
'pickle',
'gecko',
'dunny',
'movie',
'jerry',
'winton',
'lulu',
'budgie',
'leaf',
'bilby',
'spies',
'nurse',
'muffin',
'army',
'wand',
'beach',
'heart',
'leech',
'madge',
'jungle',
'barky',
'freeze',
'mini',
'dance',
'uppy',
'shaun',
'fairy',
'floppy',
'queen',
'space',
'games',
'doreen',
'ticket',
'hammer',
'school',
'ludo',
'whale',
'fido',
'missy',
'peanut',
'curry',
'mort',
'dragon',
'bumpy',
'tooth',
'camper',
'rain',
'wendy',
'drive',
'circus',
'cafe',
'honey',
'gnomes',
'sticky',
'coco',
'dump',
'buddy',
'walrus',
'island',
'monkey',
'minute',
'frisky',
'hockey',
'heavy',
'fancy',
'quiet',
'jocks',
'puppet',
'race',
'keepy',
'bingo',
'dougie',
'shame',
'rusty',
'chill',
'quest',
'swings',
'butler',
'worms',
'apple',
'bones',
'lychee',
'jack',
'pompom',
'chest',
'ninja',
'robot',
'real',
'lava',
'bluey',
'duck',
'radley',
'beans',
'taxi',
'chimp',
'sheep',
'life',
'stump',
'fruit',
'granny',
'chilli',
'crabs',
'nits',
'rita',
'salon',
'squash',
'turtle',
'indy',
'bucks',
'letter',
'doctor',
'tina',
'ziggy',
'trixie',
'quads',
'nails',
'claw',
'easter',
'magic',
'boats',
'dirt',
'bandit',
'gnome',
'market',
'judo',
'nana',
'yoga',
'cake',
'swim',
'creek',
'romeo',
'puppy',
'gerald',
'onesie',
'squish',
'fluffy',
'bunny',
'hotel',
'janet',
'chunky',
'polly',
'nomad',
'phones',
'chloe',
'brumm',
'magpie',
'statue',
'canoe',
'lucky',
'pony',
'parcel',
'floss',
'chess',
'pizza',
'shops',
'gladys'
]
// IMPORTANT
// if you add words {WORDS.length} days afer getWordOfDay() EPOCH
// need to update the date to avoid shifting the MOD value
