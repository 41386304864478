import {
  InformationCircleIcon,
  ChartBarIcon,
  CogIcon,
  ClipboardListIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { PromoModal } from './components/modals/PromoModal'
import { StatsModal } from './components/modals/StatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import {
  GAME_TITLE,
  GAME_SUBTITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGES,
  HARD_MODE_ALERT_MESSAGE,
} from './constants/strings'
import {
  // MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  GAME_LOST_INFO_DELAY,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  isWordInWordList,
  isWinningWord,
  solution,
  findFirstUnusedReveal,
  unicodeLength,
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
  setPromoViewedStorage,
  getPromoViewedStorage,
} from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'

const MAX_WORD_LENGTH = solution.length
const AWS = require('aws-sdk')
AWS.config.region = 'us-east-1'
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:6637d01e-05ea-413a-8361-72cb62879f74',
})

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isPromoViewed, setIsPromoViewed] = useState(getPromoViewedStorage())
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      var msg =
        CORRECT_WORD_MESSAGES[
          Math.floor(Math.random() * CORRECT_WORD_MESSAGES.length)
        ]
      showErrorAlert(msg + ' The word was ' + solution, {
        persist: true,
      })
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    } else {
      if (!isPromoViewed) {
        // hiding initial pop up for now 4/11/22 -dj
        // setTimeout(() => {
        //   setIsPromoModalOpen(true)
        // }, WELCOME_INFO_MODAL_MS)
      }
    }
  }, [isPromoViewed])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const handlePromoViewed = (isPromoViewed: boolean) => {
    setIsPromoViewed(isPromoViewed)
    setPromoViewedStorage(isPromoViewed)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    var userscore = 0

    const delayMs = REVEAL_TIME_MS * 2
    setTimeout(() => {
      if (isGameWon) {
        const winMessage =
          WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
        const delayMs = REVEAL_TIME_MS * 2

        showSuccessAlert(winMessage, {
          delayMs,
        })
        setTimeout(() => {
          setIsStatsModalOpen(true)
        }, delayMs)
        userscore = guesses.length
      }
    }, delayMs)

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, GAME_LOST_INFO_DELAY)

      userscore = 7
    }

    var d = new Date()
    var game_date = parseInt(
      d.getFullYear() +
        '' +
        (d.getMonth() + 1 + '').padStart(2, '0') +
        '' +
        d.getDate()
    )
    if (isGameWon || isGameLost) {
      var getparams = {
        TableName: 'users_scores',
        Key: {
          game_date: game_date,
        },
      }
      ;(async () => {
        var client = new AWS.DynamoDB.DocumentClient()
        var currentScores = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 }
        client.get(getparams, function (err, data) {
          if (err) {
            // console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
          } else {
            // console.log("GetItem succeeded:", JSON.stringify(data, null, 2));
            if (data.Item) {
              currentScores = JSON.parse(data.Item.scores)
            }
          }

          currentScores[userscore]++

          var putparams = {
            Item: {
              scores: JSON.stringify(currentScores),
              game_date: game_date,
            },
            ReturnConsumedCapacity: 'TOTAL',
            TableName: 'users_scores',
          }

          client.put(putparams, function (err, data) {
            if (err) {
              // console.error("Unable to add item. Error JSON:", JSON.stringify(err, null, 2));
            } else {
              // console.log("Added item:", JSON.stringify(data, null, 2));
            }
          })
        })
      })()
    }
  }, [isGameWon, isGameLost, showSuccessAlert, guesses])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === MAX_WORD_LENGTH)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    const winningWord = isWinningWord(currentGuess)

    if (
      unicodeLength(currentGuess) === MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)

        var msg =
          CORRECT_WORD_MESSAGES[
            Math.floor(Math.random() * CORRECT_WORD_MESSAGES.length)
          ]
        showErrorAlert(msg + ' The word was ' + solution, {
          persist: true,
          delayMs: REVEAL_TIME_MS * MAX_WORD_LENGTH + 1,
        })
      }
    }
  }

  return (
    <div className="pt-2 pb-8 mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-1 mt-2">
        <h1 className="blueyfont blueyblue text-5xl ml-2.5 grow font-bold dark:text-white">
          {GAME_TITLE}
        </h1>
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer stroke-slate dark:stroke-white info-icon"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer stroke-slate dark:stroke-white stats-icon"
          onClick={() => setIsStatsModalOpen(true)}
        />
        <CogIcon
          className="h-6 w-6 mr-3 cursor-pointer stroke-slate dark:stroke-white settings-icon"
          onClick={() => setIsSettingsModalOpen(true)}
        />
      </div>
      <div className="flex w-80 mx-auto items-center mb-1 mt-2">
        <div className="w-40">
          <span className="blueyfont italic text-white text-xl ml-2.5 font-bold dark:text-white">
            {GAME_SUBTITLE}
          </span>
        </div>
        <div className="w-40">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.buymeacoffee.com/djohnson.tech"
          >
            <img
              className="buymebutton"
              alt="Buy Me a Toffee Apple"
              height="28"
              width="160"
              src="https://img.buymeacoffee.com/button-api/?text=Buy me a toffee apple&emoji=🍎&slug=djohnson.tech&button_colour=f0faff&font_colour=000000&font_family=Bree&outline_colour=000000&coffee_colour=FFDD00"
            />
          </a>
        </div>
      </div>
      <div className="w-80 mx-auto mb-1 mt-2">
        <div className="flex justify-center text-teal-900 grow dark:text-white font-bold mt-3 text-sm">
          <ClipboardListIcon className="h-6 pt-1 justify-center" />
          <a
            href="https://forms.gle/YPyEJWvmRDLJh23f8"
            target="_blank"
            rel="noreferrer"
            className="suggest-link underline mr-5 pt-1"
          >
            suggest words
          </a>
          <a
            href="https://forms.gle/FNdonPFu7FK2f6dB9"
            target="_blank"
            rel="noreferrer"
            className="bg-sky-500 hover:bg-sky-700 text-white dark:text-black dark:bg-gray-200 py-1 px-2 rounded feedback-button"
          >
            Feedback?
          </a>
          <img
            alt="Keep Uppy Balloon"
            className="float-right cursor-pointer ml-2 py-1"
            height="20"
            width="25"
            src="keepyuppy.png"
            onClick={() => setIsPromoModalOpen(true)}
          />
        </div>

        <div className=" w-80 mx-auto mb-4 mt-3">
          <div className="grow dark:text-white text-center text-xs italic text-teal-700">
            Daily solutions will vary in length from 4-6 letters,
            <br />
            indicated by the number of blanks.
            <br />
            Last updated: Oct 8, 2023
          </div>
        </div>
      </div>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClassName={currentRowClass}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <PromoModal
        isOpen={isPromoModalOpen}
        handleClose={() => {
          setIsPromoModalOpen(false)
          handlePromoViewed(true)
        }}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
        isDarkMode={isDarkMode}
        isHighContrastMode={isHighContrastMode}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={isDarkMode}
        handleDarkMode={handleDarkMode}
        isHighContrastMode={isHighContrastMode}
        handleHighContrastMode={handleHighContrastMode}
      />

      <AlertContainer />
    </div>
  )
}

export default App
