import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'
import { GAME_SUBTITLE } from '../constants/strings'
import { WIN_MESSAGES, CORRECT_WORD_MESSAGES } from '../constants/strings'
import { MAX_CHALLENGES } from '../constants/settings'

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean
) => {

    var msg = '';
    if ( !lost )
    {
      msg =
        WIN_MESSAGES[
          Math.floor(Math.random() * WIN_MESSAGES.length)
        ]
    }
    else
    {
      msg =
        CORRECT_WORD_MESSAGES[
          Math.floor(Math.random() * CORRECT_WORD_MESSAGES.length)
        ]
    }

  navigator.clipboard.writeText(
    `${GAME_TITLE} - ${GAME_SUBTITLE}\n\n#${solutionIndex}: ${
      lost ? 'X' : guesses.length
    }/${MAX_CHALLENGES}${isHardMode ? '*' : ''} - ${msg}\n\n` +
      generateEmojiGrid(guesses, getEmojiTiles(isDarkMode, isHighContrastMode))+`\n\n`+
      `https://bluedle.games #bluedle #bluey`

  )
}

export const generateEmojiGrid = (guesses: string[], tiles: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return tiles[0]
            case 'present':
              return tiles[1]
            default:
              return tiles[2]
          }
        })
        .join(' ')
    })
    .join('\n')
}

const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? '🟧' : '🟩')
  tiles.push(isHighContrastMode ? '🟦' : '🟨')
  tiles.push(isDarkMode ? '⬛' : '⬜')
  return tiles
}
