import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShare: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
}: Props) => {
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
      </BaseModal>
    )
  }
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram gameStats={gameStats} />
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
              <button
                type="button"
                className="share-button my-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                onClick={() => {
                  shareStatus(
                    guesses,
                    isGameLost,
                    isHardMode,
                    isDarkMode,
                    isHighContrastMode
                  )
                  handleShare()
                }}
              >
                {SHARE_TEXT}
              </button>
            </div>
          <div>
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div>
            <div className="text-sm">
                Enjoying Bluedle?<br />Support the site!
                <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.buymeacoffee.com/djohnson.tech"
                  >
                <img
                  height="28" width="160"
                  className="modal-buyme-link mt-2 buymebutton"
                  alt="Buy Me a Toffee Apple"
                  src="https://img.buymeacoffee.com/button-api/?text=Buy me a toffee apple&emoji=🍎&slug=djohnson.tech&button_colour=FFDD00&font_colour=000000&font_family=Bree&outline_colour=000000&coffee_colour=ffffff"
                />
                </a>
                <div className="mt-1">
                <a className="underline amz-link" rel="noreferrer" target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=bluedle-20&linkCode=ur2&linkId=5a597c8eb3c0a54d15f1d4757067435c&camp=1789&creative=9325&index=toys-and-games&keywords=bluey toys">Shop Bluey on Amazon</a>
                </div>
            </div>
        </div>
      )}
    </BaseModal>
  )
}
