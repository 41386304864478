import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const PromoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Is it Keepy Uppy?" isOpen={isOpen} handleClose={handleClose}>
      <div className="text-sm text-gray-500 dark:text-gray-300 px-2">
        <img alt="Keep Uppy Balloon" className="float-left" height="20" width="25" src="keepyuppy.png" />
        <img alt="Keep Uppy Balloon" className="float-right" height="20" width="25" src="keepyuppy.png" />
      </div>
      <div className="text-sm text-gray-500 dark:text-gray-300 px-6">
        Well, that's up to you!
      </div>
      <hr className="my-2" />
      <div className="text-sm text-gray-500 dark:text-gray-300 px-2">
        <img
              alt="User Stats"
              src="users.jpg"
            />
      </div>
      <div className="mt-2 text-sm text-gray-500 dark:text-gray-300 text-left">
        With ~1,500 users daily, Bluedle has grown up to be so much more than I could've imagined.
        <br />
        <br />
        The bittersweet side of this is the cost associated with the success.
        <br />
        <br />
        To keep the site ad-free, I'm humbly asking for help. If just 1% of people who see this use the button below to <b>buy me 1 or more toffee apples</b> ($3/ea), Bluedle can add a month to its lifespan. If the same number of people were to <b>join the monthly membership</b> instead, <span className="italic">Bluedle could live forever!</span>
        <br />
        <br />
        So please consider supporting your favorite Bluey-based word game, and together we can keep this thing going!
      </div>
      <hr className="my-2" />
      <div className="text-sm text-gray-500 dark:text-gray-300">
        <a
            rel="noreferrer"
            target="_blank"
            href="https://www.buymeacoffee.com/djohnson.tech"
          >
        <img
          height="48" width="280"
          className="modal-buyme-link-promo mt-2 buymebutton"
          alt="Buy Me a Toffee Apple"
          src="https://img.buymeacoffee.com/button-api/?text=Buy me a toffee apple&emoji=🍎&slug=djohnson.tech&button_colour=FFDD00&font_colour=000000&font_family=Bree&outline_colour=000000&coffee_colour=ffffff"
        />
        </a>
      </div>
      <hr className="my-2" />
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="T" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="H"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="N" />
        <Cell value="K" />
      </div>
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Y" />
        <Cell value="O" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="U"
          status="present"
        />
        <Cell value="!" />
      </div>
      <div className="flex justify-center mb-1 mt-4">
        <img alt="Keep Uppy Balloon" width="75" height="59" src="keepyuppy.png" />
      </div>
    </BaseModal>
  )
}
