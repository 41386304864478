import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How To Play" isOpen={isOpen} handleClose={handleClose}>
      <div className="text-sm text-gray-500 dark:text-gray-300 px-6">
        {process.env.REACT_APP_GAME_DESCRIPTION}
      </div>
      <hr className="my-3" />
      <div className="text-sm text-gray-500 dark:text-gray-300 px-2">
        <b>Tip:</b> Possible solutions may include episode titles, themes, games, recurring objects and characters, and anything else that makes you think of the Bluey Universe.
      </div>
      <hr className="my-3" />
      <div className="text-sm text-gray-500 dark:text-gray-300">
        The number of blanks is the number of letters in the word. Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </div>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="B"
          status="correct"
        />
        <Cell value="L" />
        <Cell value="U" />
        <Cell value="E" />
        <Cell value="Y" />
      </div>
      <div className="text-sm text-gray-500 dark:text-gray-300">
        The letter B is in the word and in the correct spot.
      </div>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="R" />
        <Cell value="I" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="T"
          status="present"
        />
        <Cell value="A" />
      </div>
      <div className="text-sm text-gray-500 dark:text-gray-300">
        The letter T is in the word but in the wrong spot.
      </div>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="N" />
        <Cell value="O" />
        <Cell value="M" />
        <Cell value="A" />
        <Cell value="D" />
        <Cell isRevealing={true} isCompleted={true} value="S" status="absent" />
      </div>
      <div className="text-sm text-gray-500 dark:text-gray-300">
        The letter S is not in the word in any spot.
      </div>
      <hr className="my-3" />
      <div className="text-sm text-gray-500 dark:text-gray-300">
        Brought to you by <a href="https://djohnson.tech" target="_blank" rel="noreferrer" className="text-decoration-underline">djohnson.tech</a>
      </div>
        <div className="mt-2 text-sm text-gray-500 dark:text-gray-300">Use the Feedback button on the main page to submit praise, concerns, bugs, or feature ideas to help keep Bluedle the best Bluey based word game out there!</div>

    </BaseModal>
  )
}
